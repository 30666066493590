// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-onze-gemeenschap-js": () => import("./../src/pages/onze-gemeenschap.js" /* webpackChunkName: "component---src-pages-onze-gemeenschap-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-wieiswie-js": () => import("./../src/pages/wieiswie.js" /* webpackChunkName: "component---src-pages-wieiswie-js" */)
}

